import React from 'react';
import { graphql } from 'gatsby';
import { navigate } from '@reach/router';

import { useLocale } from '@app/hooks';
import { Layout, Page as P, Sidebar, Seo } from '@app/components';

type BlogPropTypes = {
  data: {
    markdownRemark: {
      frontmatter: {
        title: string;
        path: string;
        parentMenu: string;
        date: string;
        head?: boolean;
      };
      html: string;
    };
  };
};

const Blog: React.FC<BlogPropTypes> = ({ data }) => {
  const SCOPE_OPTIONS = {
    scope: 'templates.page',
  };
  const { t } = useLocale();

  const { frontmatter, html } = data.markdownRemark;

  if (data.markdownRemark === null || html === '') {
    navigate('/404');
  }

  return (
    <Layout>
      <Seo title={frontmatter.title} />
      {frontmatter.head ?? (
        <P.Head
          title={frontmatter.title}
          date={frontmatter.date}
          breadcrumb={[
            { path: '', name: t('labels.home', SCOPE_OPTIONS) },
            {
              path: `/${frontmatter.parent}`,
              name: 'Blog',
            },
            { path: `/${frontmatter.path}`, name: frontmatter.title },
          ]}
        />
      )}

      <div className="post-content">
        <div className="container mx-auto px-4">
          <div className="grid lg:grid-cols-4 gap-6 items-start">
            <div className="lg:col-span-4">
              <div
                className="py-16 prose lg:prose-lg max-w-none space-y-8"
                dangerouslySetInnerHTML={{ __html: html }}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Blog;

export const query = graphql`
  query ($language: String!, $slug: String!) {
    markdownRemark(
      frontmatter: { path: { eq: $slug }, language: { eq: $language }, type: { eq: "blog" } }
    ) {
      frontmatter {
        title
        date(formatString: "MMMM D, YYYY", locale: $language)
        path
      }
      html
    }

    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
